import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  ContactUsContainer,
  ContactUsInnerContainer,
} from "../../styles/contactus";
import { ContactUsForm } from "../../sections/auth/contactus";
import { Box } from "@mui/material";
import "@fontsource/heebo";
import Image from "../Image";
import Container from "@mui/material/Container";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ColorBar from "../ColorBar";

export default function ContactUsBody() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <ContactUsContainer>
      <ContactUsInnerContainer>
        <Box
          align="justify"
          display={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            lineHeight: 2,
            textAlign: "justify",
            fontSize: 20,
            fontFamily: "heebo",
            pl: { md: 8, xl: 20 },
            pr: { md: 8, xl: 20 },
            pt: 0,
            pb: 0,
          }}
        >
          <ColorBar />
          <Typography
            sx={{
              fontFamily: "heebo",
              fontSize: 30,
              fontWeight: 700,
              marginBottom: "40px",
            }}
          >
            Contact Us
          </Typography>
          <Box
            display={matches ? "column" : "none"}
            sx={{
              justifyContent: "center",
              // mt: 10,
              height: "50%",
              width: "100%",
            }}
          >
            <Container
              sx={{
                p: 1,
                backgroundColor: "#FFF1EE",
                borderRadius: "20px",
                overflow: "visible",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                justifyContent="center"
                margin={"10px"}
                sx={{ fontSize: "15px", textAlign: "center" }}
              >
                (+65)85011436 <br /> sgmuslimmatrimony@gmail.com
              </Typography>
            </Container>
          </Box>
          <Box sx={{ mt: 3, pb: matches ? 0 : 20, backgroundColor: "none" }}>
            <ContactUsForm />
          </Box>
        </Box>
      </ContactUsInnerContainer>
      <ContactUsInnerContainer
        display="column"
        // grid-template-columns="repeat(4, auto)"
        justify-content="center"
      >
        <Box
          display={matches ? "column" : "flex"}
          // display="flex"
          sx={{
            alignItems: "center",
            justifyContent: "center",
            mt: matches ? 0 : 10,
            height: "30%",
            width: matches ? "auto" : "50%",
            pX: 20,
          }}
        >
          <Image src="../images/contactus/ContactUs.png"></Image>
        </Box>
        <Box
          display={matches ? "none" : "column"}
          sx={{
            justifyContent: "center",
            // mt: 10,
            height: "50%",
            width: "90%",
          }}
        >
          <Container
            sx={{
              p: 3,
              backgroundColor: "#FFF1EE",
              borderRadius: "20px",
              overflow: "visible",
            }}
          >
            <Typography
              justifyContent="center"
              margin={"10px"}
              sx={{ fontSize: "15px" }}
            >
              <WhatsAppIcon sx={{ mx: 2, fontSize: "20px" }} />
              (+65)85011436
              <EmailIcon sx={{ mx: 2, fontSize: "20px" }} />
              sgmuslimmatrimony@gmail.com
            </Typography>
          </Container>
        </Box>
      </ContactUsInnerContainer>
    </ContactUsContainer>
  );
}
